<template>
  <div class="newsMessage">
    <div class="meetingMsg">
      <div class="img">
        <img :src="newsList.poster" alt="" srcset="" />
      </div>
      <div class="other">
        <p
          class="name"
          :title="newsList.name_en | priorFormat(newsList.name_zh, LOCALE)"
        >
          {{ newsList.name_en | priorFormat(newsList.name_zh, LOCALE) }}
        </p>
        <div class="number">
          <div class="views">
            浏览：<span>{{ newsList.views }}</span>
          </div>
          <div class="offer">
            报名：<span>{{ newsList.apply_count }}</span>
          </div>
        </div>
        <div class="time">
          <img
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/msgclock.png"
            alt=""
          />
          {{ newsList.start_time | secondFormat("LLL") }}
        </div>
        <div class="address">
          <img
            v-if="newsList.event_type != 1"
            src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaStatic/img/msg_address.png"
            alt=""
          />
          <span v-if="newsList.event_type === 1"> 线上活动 </span>
          <span v-else>
            {{ newsList.country | countryFormat(LOCALE) }}
            {{ newsList.address_en | priorFormat(newsList.address_zh, LOCALE) }}
          </span>
        </div>
        <div class="btn" v-if="newsList.need_apply == 1">
          <el-button
            type="primary"
            size="small"
            v-if="newsList.apply_status === 0"
            @click="enroll"
            style="width: 186px"
            >立即报名
          </el-button>
          <el-button type="primary" size="small" v-else disabled
            >已报名
          </el-button>
          <el-button
            v-if="hasLink"
            type="primary"
            plain
            size="small"
            @click="handleJump"
            style="width: 186px"
            >进入直播间
          </el-button>
        </div>
      </div>
    </div>
    <div class="mettingDesc">
      <div class="desc" v-if="contentHtml">
        <div v-html="contentHtml"></div>
      </div>
      <!-- <div class="eCode">
        <img :src="ecode" alt="" />
        <p class="tips">微信扫一扫，分享给朋友</p>
      </div> -->
    </div>

    <el-dialog
      title="活动报名"
      :visible.sync="dialogVisible"
      width="900px"
      @close="clearDialog"
    >
      <div class="applyBox">
        <el-form
          v-if="dialogVisible"
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          class="form"
          label-position="left"
          :validate-on-rule-change="false"
        >
          <el-row>
            <el-col :span="11">
              <el-form-item label="企业名称" prop="company_name">
                <el-input
                  v-model="form.company_name"
                  placeholder="请输入企业名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
              <el-form-item label="联系人" prop="contacts">
                <el-input
                  v-model="form.contacts"
                  placeholder="请输入联系人"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="职位" prop="job_title">
                <el-input
                  v-model="form.job_title"
                  placeholder="请输入职位名称"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="2">
              <el-form-item label="联系方式" prop="contact_information">
                <el-input
                  v-model="form.contact_information"
                  placeholder="请输入联系方式"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" >
              <el-form-item label="邮箱" prop="mailbox">
                <el-input
                  v-model="form.mailbox"
                  placeholder="请输入邮箱"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  type="textarea"
                  placeholder="备注信息"
                  v-model="form.remark"
                  maxlength="200"
                  :rows="6"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="applyBtns">
          <el-button
            @click="restForm"
            class="applyBtn applyBtnCan"
            size="medium"
            >取 消
          </el-button>
          <el-button
            :loading="btnLoading"
            class="confirmBtn applyBtn"
            type="primary"
            size="medium"
            @click="submit('form')"
            >提 交
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { priorFormat } from "@/basePlugins/filters";

export default {
  data() {
    return {
      img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/apply.png",
      defaultImg: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/active.png",
      conlseVisible: false,
      form: {
        company_name: "",
        contacts: "",
        contact_information: "",
        job_title: "",
        remark: "",
        mailbox:"",
      },
      rules: {
        company_name: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        contacts: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        job_title: {
          required: true,
          message: " ",
          trigger: "blur",
        },
        contact_information: {
          // validator: async (rule, value, callback) => {
          //   if (!value) {
          //     callback(new Error(" "));
          //   } else {
          //     let params = {};
          //     params.event_id = this.$route.query.id;
          //     if (this.IS_LOGIN) {
          //       params.user_id = this.USER_ID;
          //     } else {
          //       params.phone = value;
          //     }
          //     let res = await this.$store.dispatch(
          //       "API_position/checkEventApply",
          //       params
          //     );
          //     if (res.success) {
          //       if (res.data.status === 1) {
          //         if (this.IS_LOGIN) {
          //           callback(new Error("用户已报名"));
          //         } else {
          //           callback(new Error("报名信息已存在"));
          //         }
          //       } else {
          //         callback();
          //       }
          //     } else {
          //       callback(new Error(" "));
          //     }
          //   }
          // },
          // trigger: "blur",
          required: true,
          message: " ",
          trigger: "blur",
        },
      },
      newsList: {},
      backUrl:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/pcBanner2.png",
      status: 0,
      userInfo: "",
      btnLoading: false,
      contentHtml: "",
      ecode: "",
      hasLink: false,
      dialogVisible: false,
      registerBtnLoading: false,
    };
  },
  mounted() {
    this.getDetail();
    this.update_counts();
    // this.getEcode();
    this.userInfo = this.USER_INFO;
  },
  computed: {},
  methods: {
    async getEcode() {
      let params = {
        page: "pages/sharePage/index",
        width: 300,
        scene: `6&${this.$route.query.id}`,
        source: this.PJSource,
      };
      let ecode = await this.$store.dispatch("API_position/getEcode", params);
      this.ecode = ecode.file;
    },
    async update_counts() {
      let res = await this.$store.dispatch(
        "API_index/association_event_views",
        { event_id: this.$route.query.id }
      );
    },
    clearDialog() {
      this.form = {
        company_name: "",
        contacts: "",
        contact_information: "",
        job_title: "",
        remark: "",
        mailbox: "",
      };
    },
    restForm() {
      this.$refs.form.resetFields();
      this.form = {
        company_name: "",
        contacts: "",
        contact_information: "",
        job_title: "",
        remark: "",
        mailbox: "",
      };
      this.dialogVisible = false;
    },
    toConsole() {
      this.$router.push({ path: "/console/company/basic" });
    },
    async submit(formName) {
      
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let query = {};
          query.event_id = this.$route.query.id;
          if (this.IS_LOGIN) {
            query.user_id = this.USER_ID;
          } else {
            query.phone = this.form.contact_information;
          }
          let res = await this.$store.dispatch(
            "API_position/checkEventApply",
            query
          );
          if (res.success) {
            if (res.data.status === 1) {
              this.$message({
                message: "报名信息已存在",
                type: "warning",
              });
            } else {
              this.btnLoading = true;
              let params = {};
              if (this.IS_LOGIN) {
                params.user_id = this.USER_INFO.id;
                params.company_id = this.USER_INFO.company_id;
              }
              params.event_id = this.$route.query.id;
              params.extras = JSON.stringify({
                company: this.form.company_name,
                contacts: this.form.contacts,
                title: this.form.job_title,
                contact_information: this.form.contact_information,
                remark: this.form.remark,
                mailbox: this.form.mailbox||"",
              });

              let data = await this.$store.dispatch(
                "API_index/association_event_apply",
                params
              );
              console.log(data);
              this.btnLoading = false;
              this.dialogVisible = false;
              this.getDetail();
              if (data.success) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
              }
            }
          }
        } else {
          return false;
        }
      });
    },
    async getDetail() {
      //siffa/api/association_event/list
      let data = await this.$store.dispatch("API_index/association_event", {
        association_event_id: this.$route.query.id,
        user_id: this.USER_INFO.id,
        // company_id: this.USER_INFO.company_id,
      });
      if (data.success) {
        this.newsList = data.data[0];
        this.newsList.desc_zh = (
          this.newsList.desc_zh ? this.newsList.desc_zh : ""
        ).replace(
          /\<img/gi,
          '<img style="max-width:100%;height:auto" class="richImg"'
        );
        this.newsList.desc_en = (
          this.newsList.desc_en ? this.newsList.desc_en : ""
        ).replace(
          /\<img/gi,
          '<img style="max-width:100%;height:auto" class="richImg"'
        );
        this.contentHtml = priorFormat(
          this.newsList.desc_en,
          this.newsList.desc_zh,
          this.LOCALE
        );
        if (this.newsList.name_zh) {
          document.title = this.newsList.name_zh;
        } else if (this.newsList.name_en) {
          document.title = this.newsList.name_en;
        } else {
          document.title = "培训活动";
        }
      }
    },
    enroll() {
      this.dialogVisible = true;
      if (this.USER_INFO) {
        let msg = this.USER_INFO;
        console.log(msg)
        this.form.company_name = msg.company_name_zh
          ? msg.company_name_zh
          : msg.company_name_en;
        this.form.contacts = msg.name_zh ? msg.name_zh : msg.name_en;
        this.form.contact_information = msg.mobile;
        this.form.mailbox = msg.email||"";
        this.form.job_title = msg.job_title_zh
          ? msg.job_title_zh
          : msg.job_title_en;
      }
    },
  },
};
</script>

<style scoped lang="less">
.newsMessage {
  padding: 30px 0;
  background: #f3f7fa;
  min-height: calc(121vh - 205px);

  .meetingMsg {
    width: 1300px;
    display: flex;
    margin: 0 auto;
    background: #fff;
    padding: 24px;

    .img img {
      width: 518px;
      height: 307px;
    }

    .other {
      width: 700px;
      position: relative;
      padding-left: 24px;

      .name {
        width: 576px;
        height: 33px;
        font-size: 24px;
        font-weight: 800;
        color: #333333;
        line-height: 33px;
        margin-bottom: 24px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1; /*要显示的行数*/
        -webkit-box-orient: vertical;
      }

      .number {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;

        span {
          color: #006edf;
        }

        div {
          color: #999;
          // padding: 30px 0;
          margin-right: 30px;
        }
      }

      .time,
      .address {
        font-size: 16px;
        font-weight: 400;
        color: #3c5058;
        line-height: 22px;
        display: flex;
        align-items: center;

        img {
          margin-right: 12px;
        }
      }

      .address {
        margin: 20px 0;
      }

      .btn {
        position: absolute;
        bottom: 0;
      }
    }
  }

  .mettingDesc {
    // display: flex;
    // justify-content: space-between;
    overflow: hidden;
    margin: 0 auto;
    width: 1300px;
    margin-top: 16px;

    .desc {
      float: left;
      width: 993px;
      background: #fff;
      padding: 30px;
      word-break: break-all;
    }

    .eCode {
      width: 291px;
      height: 329px;
      float: right;
      background: #fff;
      text-align: center;
      padding: 24px;

      img {
        width: 243px;
      }

      .tips {
        font-size: 16px;
        padding-top: 16px;
      }
    }
  }
}

.applyTitle {
  padding: 10px 0 10px 20px;
  color: #1f292e;
  font-size: 14px;
  border-bottom: 1px solid #f2f2f3;
}

.applyBtns {
  text-align: center;
  padding: 0 0 32px;

  .applyBtn {
    width: 115px;
  }

  .applyBtnCan {
    background: #ffffff;
  }

  .applyBtnCan:hover {
    color: #606266 !important;
    border-color: #dcdfe6 !important;
  }

  .confirmBtn {
    background: #7ec9f1;
    border: none;
  }
}

.demo-input-suffix {
  padding-left: 20px;
  display: flex;
  white-space: nowrap;

  align-items: center;

  .input {
    width: 220px;
  }
}

.openTitle {
  font-weight: bold;
}

.open {
  margin: 20px 0 0;

  i {
    color: #4a5a81;
    margin-left: 5px;
    border: 1px solid #4a5a81;
    border-radius: 50%;
  }
}
</style>
